module.exports.rights = {
  roleSpecific: {
    system: ["Administrator"],
    userAdministration: ["UserAdministrator"],
    contact: ["Contact"]
  },
  menu: {
    general: {
      fileEntry: ["Contact"],
      files: ["Contact"],
      patients: ["Contact"]
    },
    patients: {
      add: ["Contact"],
      view: ["Contact"],
      edit: ["Contact"],
      delete: ["Contact"]
    }
  }
};
