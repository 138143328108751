<template>
  <div class="resetPassword pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="navigate('home')"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button> -->
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ isFirstPasswordProp ? $t('session.resetPassword.pageTitleNew') : $t('session.resetPassword.pageTitleReset') }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <div class="kt-login-inpage">
      <div class="kt-login-inpage__container">
        <div class="kt-login-inpage__wrapper">
          <div
            class="kt-login-inpage__card"
            style="width: 340px;"
          >
            <b-form @submit.prevent="onSubmit">
              <!-- Invalid link -->
              <p
                v-show="idState !== null || tokenState !== null"
                class="kt-login__invalidMessage text-danger"
              >
                {{ isFirstPasswordProp ? $t("session.resetPassword.validations.invalidSetupLink") : $t("session.resetPassword.validations.invalidResetLink") }}
              </p>

              <!-- password -->
              <b-form-group
                class="kt-form-group--mb-sm"
                :label="$t('session.resetPassword.password')"
                :invalid-feedback="passwordInvalidFeedback"
                :state="passwordState"
              >
                <b-form-input
                  ref="passwordField"
                  v-model="password"
                  class="kt-formField--password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :state="passwordState"
                  @focus="arePasswordLabelsVisible = true"
                ></b-form-input>
                <!-- show password -->
                <div class="kt-show-password">
                  <b-button
                    class="kt-show-password__button"
                    tabindex="-1"
                    @click="onShowPassword('passwordField')"
                  >
                    <b-icon
                      v-show="isPasswordVisible"
                      :class="'text-' + $systemSettings.theme"
                      icon="eye-slash-fill"
                    ></b-icon>
                    <b-icon
                      v-show="!isPasswordVisible"
                      icon="eye-fill"
                    ></b-icon>
                  </b-button>
                </div>
                <!-- password badges -->
                <div
                  v-show="arePasswordLabelsVisible"
                  class="kt-password-entry-badges"
                >
                  <!-- minLenght -->
                  <span
                    v-if="!password || !(password.length >= 12)"
                    class="kt-badge kt-badge--blue"
                  >{{ $t("passwordBadges.minLenghtBadge") }}</span>
                  <!-- lowercase -->
                  <span
                    v-if="!(/^(?=.*[a-z]).{1,}$/.test(password))"
                    class="kt-badge kt-badge--blue"
                  >{{ $t("passwordBadges.lowercaseBadge") }}</span>
                  <!-- uppercase -->
                  <span
                    v-if="!(/^(?=.*[A-Z]).{1,}$/.test(password))"
                    class="kt-badge kt-badge--blue"
                  >{{ $t("passwordBadges.uppercaseBadge") }}</span>
                  <!-- number -->
                  <span
                    v-if="!(/^(?=.*\d).{1,}$/.test(password))"
                    class="kt-badge kt-badge--blue"
                  >{{ $t("passwordBadges.numberBadge") }}</span>
                </div>
              </b-form-group>

              <!-- passwordCheck -->
              <b-form-group
                :invalid-feedback="passwordCheckInvalidFeedback"
                :state="passwordCheckState"
              >
                <b-form-input
                  ref="passwordCheckField"
                  v-model="passwordCheck"
                  class="kt-formField--password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :placeholder="$t('session.resetPassword.repeatPassword')"
                  :state="passwordCheckState"
                  @blur="passwordCheckValidation = true"
                ></b-form-input>
                <!-- show password -->
                <div class="kt-show-password">
                  <b-button
                    class="kt-show-password__button"
                    tabindex="-1"
                    @click="onShowPassword('passwordCheckField')"
                  >
                    <b-icon
                      v-show="isPasswordVisible"
                      :class="'text-' + $systemSettings.theme"
                      icon="eye-slash-fill"
                    ></b-icon>
                    <b-icon
                      v-show="!isPasswordVisible"
                      icon="eye-fill"
                    ></b-icon>
                  </b-button>
                </div>
              </b-form-group>

              <!-- footer -->
              <div class="kt-login__footer">
                <!-- left -->
                <div>
                </div>
                <!-- submit -->
                <div>
                  <b-button
                    class="my-2"
                    :variant="$systemSettings.theme"
                    pill
                    type="submit"
                  >
                    {{ $t('session.resetPassword.submit') }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactsServices from "@/services/API/contactsServices";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [error],
  props: {
    isFirstPasswordProp: {
      type: Boolean
    },
    idProp: {
      type: Number,
      default: null
    },
    tokenProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id: this.idProp,
      token: this.tokenProp,
      password: "",
      isPasswordVisible: false,
      passwordCheck: "",
      // others
      arePasswordLabelsVisible: false,
      // is validation active
      passwordValidation: false,
      passwordCheckValidation: false
    };
  },
  computed: {
    // form validation
    idState: function() {
      // test if empty or invalid
      return this.id && Number.isInteger(this.id) ? null : false;
    },
    tokenState: function() {
      // test if empty or invalid
      return this.token && this.token.length === 128 ? null : false;
    },
    passwordState: function() {
      if (!this.passwordValidation) return null;
      return this.password && this.password.length >= 12 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password) && this.password === this.passwordCheck ? null : false;
    },
    passwordInvalidFeedback: function() {
      // no error
      if (this.passwordState === null) return "";
      // error but no notification
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password)) return "";
      // else : passwords not equals
      return this.$t("session.resetPassword.validations.passwordNotEquals");
    },
    passwordCheckState: function() {
      if (!this.passwordCheckValidation) return null;
      return this.passwordCheck && this.passwordCheck.length > 0 && this.password === this.passwordCheck ? null : false;
    },
    passwordCheckInvalidFeedback: function() {
      // no error
      if (this.passwordCheckState === null) return "";
      // if empty
      if (!this.passwordCheck) return this.$t("validationRules.required");
      // else : passwords not equals
      return this.$t("session.resetPassword.validations.passwordNotEquals");
    }
  },
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // auto focus
    this.$refs.passwordField.focus();
  },
  methods: {
    onShowPassword(refTarget) {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.$nextTick(() => this.$refs[refTarget].focus());
    },

    // submit functions
    onValidate() {
      this.passwordValidation = true;
      this.passwordCheckValidation = true;

      return !(
        this.idState === false ||
        this.tokenState === false ||
        this.passwordState === false ||
        this.passwordCheckState === false
      );
    },
    async onSubmit() {
      try {
        // Validate Login Form
        if (this.onValidate() === false) return false;

        // Register file
        const form = {
          id: this.id,
          token: this.token,
          password: this.password
        };
        const res = await contactsServices.setPassword(form);
        if (res.data === true) {
          // Success
          if (this.isFirstPasswordProp) {
            this.$emit("alert", "success", {
              title: this.$t("session.resetPassword.notifications.passwordCreatedTitle"),
              message: this.$t("session.resetPassword.notifications.passwordCreatedText")
            });
          } else {
            this.$emit("alert", "success", {
              title: this.$t("session.resetPassword.notifications.passwordResetTitle"),
              message: this.$t("session.resetPassword.notifications.passwordResetText")
            });
          }
          this.navigate("home");
        } else if (res.data.error === "invalidLink") {
          this.id = null;
          this.token = "";
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("session.resetPassword.notifications.resetPasswordErrorTitle"),
            message: this.$t("session.resetPassword.notifications.resetPasswordErrorText")
          });
        }
      } catch (err) {
        // Other errors
        this.$emit("alert", "danger", {
          title: this.$t("session.resetPassword.notifications.resetPasswordErrorTitle"),
          message: this.$t("session.resetPassword.notifications.resetPasswordErrorText")
        });
      }
    }
  }
};
</script>

<style scoped src="@shared/views/General/Onboarding/Login/ResetPassword.scss" lang="scss"></style>
