import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@shared/views/General/Home.vue";
import ResetPassword from "@shared/views/General/Onboarding/Login/ResetPassword.vue";

import Results from "@/views/Results/Results.vue";
import ResultsTable from "@/views/Results/ResultsTable.vue";

import NotFound from "@shared/views/General/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/resetPassword/:idProp/:tokenProp",
    name: "resetPassword",
    component: ResetPassword,
    props: (route) => ({
      isFirstPasswordProp: false,
      idProp: Number(route.params.idProp) || "",
      tokenProp: route.params.tokenProp || ""
    }),
    meta: {
      noAuth: true
    }
  },
  {
    path: "/initPassword/:idProp/:tokenProp",
    name: "initPassword",
    component: ResetPassword,
    props: (route) => ({
      isFirstPasswordProp: true,
      idProp: Number(route.params.idProp) || "",
      tokenProp: route.params.tokenProp || ""
    }),
    meta: {
      noAuth: true
    }
  },

  //
  // RESULTS ROUTES
  //
  // Results
  {
    path: "/results",
    component: Results,
    // Children route "tabs"
    children: [
      {
        path: "", // default
        name: "results",
        component: ResultsTable,
        props: {
          filterPresetProp: ""
        }
      },
      {
        path: "pending",
        name: "results/pending",
        component: ResultsTable,
        props: {
          filterPresetProp: "pending"
        }
      }
    ]
  },

  // 404 page
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFound,
    meta: {
      layout: "fullWidth",
      activeTopMenuName: ""
    }
  }
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export function navigate(routeName, props, query) {
  const route = { name: routeName };
  if (props) { route.params = props; }
  if (query) { route.query = query; }
  if (
    (router.currentRoute.name !== route.name) ||
    (route.params && JSON.stringify(router.currentRoute.params) !== JSON.stringify(route.params))
  ) {
    router.push(route);
  }
}
// history manipulations
export function saveParamsInRouteQuery(params) {
  const formattedQuery = createQueryFromParams(params);

  const newRoute = {
    name: router.currentRoute.name,
    params: router.currentRoute.params,
    query: formattedQuery.queryObject
  };
  const { href } = router.resolve(newRoute);
  // router.currentRoute.path + (query.length ? "?" : "") + query.join("&")
  window.history.replaceState(window.history.state, null, href);
}
export function createQueryFromParams(params) {
  if (!params) params = {};
  const queryArray = [];
  const keys = Object.keys(params);
  let totalLength = router.currentRoute.path.length + 1;
  for (const key of keys) {
    if (params[key] !== "" && params[key] !== null) {
      // stringify
      if (typeof params[key] === "object") {
        params[key] = JSON.stringify(params[key]);
      } else if (typeof params[key] === "boolean") {
        params[key] = params[key] ? "1" : "0";
      }
      params[key] = String(params[key]);
      // encode
      const encoded = encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      // get total length
      totalLength += (encoded.length + 1);
      // add to query
      if (totalLength < 2048) {
        queryArray.push(encoded);
      }
    } else {
      delete params[key];
    }
  }
  return {
    queryArray: queryArray,
    queryObject: params
  };
}
export function getQueryFromUrl() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}
