import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import App from "@/views/General/App.vue";
import { router } from "@/services/UI/vueRouterServices";
import i18n from "@/locales/locales";

import "@shared/css/index.scss";

const settings = Vue.observable({ systemSettings: null, userSettings: null });

Object.defineProperty(Vue.prototype, "$systemSettings", {
  get() {
    return settings.systemSettings;
  },
  set(value) {
    settings.systemSettings = value;
  }
});
Object.defineProperty(Vue.prototype, "$userSettings", {
  get() {
    return settings.userSettings;
  },
  set(value) {
    settings.userSettings = value;
  }
});
Vue.prototype.$systemSettings = {
  kitaroversion: "",
  locale: "en",
  theme: "primary",
  automaticLogout: 1,
  basicRoutes: [],
  basicRouteRegexps: [],
  application: "prescribers",

  // laboratories: [],
  // availableLaboratories: [],
  sectors: [],
  sexes: []
  // filingStatuses: [],
  // medicalStatuses: [],
  // accountingStatuses: [],
  // additionalFeeGroups: [],
  // requestErrors: [],
  // insuranceTypes: [],
  // paymentTypes: [],
  // organisationTypes: []
};
Vue.prototype.$userSettings = {
  user: null,
  savedBehaviors: {}
};

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;

//for debug and profiling
if (typeof webpackHotUpdate !== "undefined") {
  console.log("Vue is in Dev Mode enabling performance tracing for profiling");
  Vue.config.performance = true;
}

Vue.config.errorHandler = (err, vm, info) => {
  console.log("Vue error: ");
  console.log("- Info: ", info);
  console.log("- VM: ", vm);
  console.log("- Error: ", err);
};
window.onerror = (message, source, lineno, colno, error) => {
  console.log("JS error: ");
  console.log("- Message: ", message);
  console.log("- Souce: ", source);
  console.log("- Line: ", lineno);
  console.log("- Column: ", colno);
  console.log("- Error: ", error);
};

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
