<template>
  <div class="kt-results mb-4">
    <!-- period -->
    <div class="pt-1">
      <div style="width: 336px; max-width: 100%;">
        <b-form-group
          :label="$t('results.period')"
          :invalid-feedback="periodInvalidFeedback"
          :state="periodState"
        >
          <div style="display: inline-block; width: 50%; padding-right: 3px; vertical-align: top;">
            <b-form-input
              v-model="startDate"
              type="date"
              autocomplete="off"
              :state="startDateState"
              @input="searchHasChanged = true;"
              @keydown.enter.exact.prevent="onPageInput"
            ></b-form-input>
          </div>
          <div style="display: inline-block; width: 50%; padding-left: 3px; vertical-align: top;">
            <b-form-input
              v-model="endDate"
              type="date"
              autocomplete="off"
              :state="endDateState"
              @input="searchHasChanged = true;"
              @keydown.enter.exact.prevent="onPageInput"
            ></b-form-input>
          </div>
        </b-form-group>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 mb-1"></div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="fieldsFormatted"
      :items="files"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter sectors -->
          <b-th class="kt-table__th-search">
            <b-form-select
              ref="sectorFilter"
              v-model="filterSectorId"
              class="kt-table__th-search-input"
              :options="sectorsOptions"
              value-field="id"
              text-field="localisedName"
              @input="searchHasChanged = true;"
            ></b-form-select>
          </b-th>
          <!-- filter creationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterCreationDate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter accessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="filterAccessNumber"
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter hospitalisationNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="filterHospitalisationNumber"
              v-model="filterHospitalisationNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterHospitalisationNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterHospitalisationNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter patient -->
          <b-th
            class="kt-table__th-search"
            style="min-width: 120px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-button
              ref="filterPatientChoose"
              class="kt-table__th-search-input kt-table__th-search-input--choose"
              :style="filterPatient === null ? 'padding-left: 34px;' : ''"
              :variant="'outline-' + $systemSettings.theme"
              @click="$bvModal.show('patientChooseModal-' + _uid)"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            >
              <!-- search icon -->
              <b-icon
                v-show="filterPatient === null"
                class="kt-table__th-search-input__search-icon"
                icon="search"
              ></b-icon>
              <!-- value -->
              <span class="kt-table__th-search-input__text-overflow">{{ filterPatient ? filterPatient.firstName + ' ' + filterPatient.lastName : '' }}</span>
            </b-button>
            <b-button
              v-show="filterPatient"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="() => {
                filterPatient = null;
                searchHasChanged = true;
                $refs.filterPatientChoose.focus();
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filterPathologist -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="pathologists"
              searchedFieldProp="user.lastName"
              :returnFieldsArrayProp="['id', 'code', 'user.lastName', 'user.firstName', 'user.email', 'user.isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterPathologist"
              :displayedValueProp="suggestionFormatPathologist(filterPathologist)"
              :suggestionFormatProp="suggestionFormatPathologist"
              noWrapProp

              @onUpdateParent="onPathologistChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- isPathological -->
          <b-th
            v-if="filterPresetProp === ''"
            class="kt-table__th-search"
          ></b-th>
          <!-- downloaded -->
          <keep-alive>
            <b-th
              v-if="filterPresetProp === ''"
              class="kt-table__th-search"
              style="width: 61px;"
            >
              <button
                class="kt-checkbox-display-only-cont"
                @click="onClickDownloadedCheckbox"
                @keydown.space.prevent="onClickDownloadedCheckbox"
                @keydown.enter.exact.prevent="onPageInput"
              >
                <span
                  class="kt-checkbox-display-only"
                  :class="'kt-checkbox-display-only--' + $systemSettings.theme + downloadedFilterCheckboxClass"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text"><b-icon icon="download" /><!-- {{ $t("results.downloaded") }} --></span>
              </button>
            </b-th>
          </keep-alive>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap;"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('general.search') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.accessNumber }}
          <!-- is urgent -->
          <span
            v-show="data.item.isUrgent"
            style="font-size: 14px; margin: 0 0 1px 3px;"
          >
            <b-icon
              icon="flag-fill"
              variant="danger"
            ></b-icon>
          </span>
          <!-- complementary -->
          <span
            v-show="data.item.parentFileId"
            class="kt-complementary-icon"
            :class="{'kt-complementary-icon--ml-lg': !!data.item.isUrgent}"
          >
            <b-icon icon="back"></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : patient -->
      <template v-slot:cell(patient)="data">
        <!-- patient name -->
        <PersonName
          class="kt-break-word"
          :sexNameProp="data.value.sex.name || ''"
          :firstNameProp="data.value.firstName || ''"
          :lastNameProp="data.value.lastName || ''"
          :maidenNameProp="data.value.maidenName || ''"
        />
      </template>

      <!-- cell template : downloaded -->
      <template v-slot:cell(downloaded)="data">
        <div style="white-space: nowrap;">
          <span
            v-show="data.value"
            class="kt-table__cell-check kt-table__cell-check--success"
          >
            <b-icon icon="check2"></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(fileButtons)="data">
        <div style="width: 39px;">
          <!-- download -->
          <b-button
            v-if="filterPresetProp === ''"
            :ref="data.index === 0 ? 'downloadLineButton_0' : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onExport(data.item)"
          >
            <b-icon icon="download"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="(!files || !files.length) && !loading && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading && files && files.length"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>

    <!-- choose Patient Modal -->
    <b-modal
      :id="'patientChooseModal-' + _uid"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
    >
      <PatientChoose
        ref="patientChooseComponent"
        :initialFilterValuesProp="patientInitialFilterValues"
        @chosen="onPatientChosen($event)"
        @updateInitialFilterValues="patientInitialFilterValues = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import PersonName from "@shared/views/Helpers/PersonName";
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
import filesServices from "@/services/API/filesServices";
import cytologyReportsServices from "@/services/API/cytologyReportsServices";
import histologyReportsServices from "@/services/API/histologyReportsServices";
// mixins
import date from "@shared/services/UI/date";
import error from "@shared/services/UI/error";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { PersonName, PatientChoose, InputSearch },
  mixins: [error, date, saveParamsInQuery],
  props: {
    filterPresetProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "sector",
          sortable: false,
          label: this.$t("files.sector"),
          formatter: (value, _key, _item) => {
            return this.$t("sectors." + value.name);
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "creationDate",
          sortable: true,
          label: this.$t("files.creationDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "patient",
          sortable: true,
          label: this.$t("files.patient")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "pathologist",
          sortable: true,
          label: this.$t("files.pathologist"),
          formatter: (value, _key, _item) => {
            return value ? this.suggestionFormatPathologist(value) : "";
          },
          sortByFormatted: true
        }
      ],
      // table filters
      startDate: this.getIsoDate(0, -2, 0),
      endDate: this.getIsoDate(),
      filterSectorId: null,
      filterCreationDate: "",
      filterAccessNumber: "",
      filterHospitalisationNumber: "",
      filterPatient: null,
      filterPathologist: null,
      filterDownloaded: null,
      patientInitialFilterValues: {},
      // table items
      files: [],
      // options
      sectorsOptions: [],
      // saveParamsConfig
      saveParamsConfig: {
        startDate: "string",
        endDate: "string",
        filterSectorId: "number",
        filterCreationDate: "string",
        filterAccessNumber: "string",
        filterHospitalisationNumber: "string",
        filterPatient: { id: "number", firstName: "string", lastName: "string", maidenName: "string" },
        filterPathologist: {
          id: "number",
          user: {
            firstName: "string",
            lastName: "string"
          }
        },
        filterDownloaded: "boolean"
      }
    };
  },
  computed: {
    // form validation
    startDateState: function() {
      if (!this.startDate) return false;
      return this.validateDateBeforeDate(this.startDate, this.endDate) ? null : false;
    },
    endDateState: function() {
      if (!this.endDate) return false;
      return this.validateDateBeforeDate(this.startDate, this.endDate) ? null : false;
    },
    periodState: function() {
      return this.startDateState === null && this.endDateState === null ? null : false;
    },
    periodInvalidFeedback: function() {
      if (this.periodState === null) return "";
      if (!this.startDate || !this.endDate) return this.$t("validationRules.required");
      return this.$t("validationRules.endDateBeforeStartDate");
    },
    // others
    downloadedFilterCheckboxClass: function() {
      if (this.filterDownloaded === null) {
        return "";
      } else if (this.filterDownloaded === true) {
        return " kt-checkbox-display-only--checked";
      } else if (this.filterDownloaded === false) {
        return " kt-checkbox-display-only--crossed";
      }
      return "";
    },
    fieldsFormatted: function() {
      const output = this.fields;
      if (this.filterPresetProp === "") {
        output.push(
          {
            tdClass: "kt-table__td",
            thClass: "kt-table__th",
            key: "isPathological",
            sortable: true,
            label: this.$t("files.isPathological"),
            formatter: (value, _key, _item) => {
              if (value) {
                return this.$t("files.isPathologicalTrue");
              } else if (value === false) {
                return this.$t("files.isPathologicalFalse");
              } else {
                return this.$t("files.isPathologicalUnknown");
              }
            }
          }
        );
        output.push(
          {
            tdClass: "kt-table__td",
            thClass: "kt-table__th",
            key: "downloaded",
            sortable: false,
            label: "",
            formatter: (_value, _key, item) => {
              let formatted = false;
              for (const fileContact of item.fileContacts) {
                if (fileContact.contactId === this.$userSettings.user.id) {
                  if (fileContact.downloaded) {
                    formatted = true;
                  }
                  break;
                }
              }
              return formatted;
            }
          },
          {
            tdClass: "kt-table__td",
            thClass: "kt-table__th",
            key: "fileButtons",
            sortable: false,
            label: ""
          }
        );
      } else {
        output.push(
          {
            tdClass: "kt-table__td",
            thClass: "kt-table__th",
            key: "fileButtons",
            sortable: false,
            label: ""
          }
        );
      }
      return output;
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import data
      this.importSectorsOptions();

      // refresh table
      this.onPageInput();

      // auto-focus
      this.focusFirstElement();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.downloadLineButton_0) {
        this.$refs.downloadLineButton_0.focus();
      }
    },
    focusFirstElement() {
      if (this.$refs.sectorFilter) {
        this.$refs.sectorFilter.focus();
      }
    },

    importSectorsOptions() {
      const sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      sectorsOptions.unshift({ id: null, isSubscribed: true, name: "all", localisedName: this.$tc("general.allMasculine", 2) });
      this.sectorsOptions = sectorsOptions.map((sector) => {
        sector.isActive = sector.isSubscribed;
        sector.disabled = !sector.isSubscribed;
        return sector;
      });
    },

    // downloaded
    onClickDownloadedCheckbox() {
      if (this.filterDownloaded === null) {
        this.filterDownloaded = true;
      } else if (this.filterDownloaded === true) {
        this.filterDownloaded = false;
      } else if (this.filterDownloaded === false) {
        this.filterDownloaded = null;
      }
      this.searchHasChanged = true;
    },
    // patient
    onPatientChosen(patient) {
      this.filterPatient = patient ? { id: patient.id, firstName: patient.firstName, lastName: patient.lastName, maidenName: patient.maidenName } : null;
      this.searchHasChanged = true;
      this.$bvModal.hide("patientChooseModal-" + this._uid);
    },
    // pathologist
    suggestionFormatPathologist(pathologist) {
      if (!pathologist) return "";
      let output = pathologist.user.firstName + " " + pathologist.user.lastName;
      if (pathologist.user.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },
    onPathologistChosen(pathologist) {
      this.filterPathologist = pathologist ? { id: pathologist.id, user: { firstName: pathologist.user.firstName, lastName: pathologist.user.lastName } } : null;
      this.searchHasChanged = true;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
      if (this.$refs[fieldName]) this.$refs[fieldName].focus();
    },

    // update table
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // validation dates
        if (this.filterCreationDate !== "") {
          const filterCreationDate = new Date(this.filterCreationDate).getFullYear();
          if (filterCreationDate < 1900 || filterCreationDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          startDate: this.startDate,
          endDate: this.endDate,
          sectorId: this.filterSectorId,
          creationDate: this.filterCreationDate,
          accessNumber: this.filterAccessNumber,
          hospitalisationNumber: this.filterHospitalisationNumber,
          patientId: this.filterPatient ? this.filterPatient.id : null,
          pathologistId: this.filterPathologist ? this.filterPathologist.id : null,
          downloaded: this.filterPresetProp === "" ? this.filterDownloaded : null,
          preset: this.filterPresetProp
        };

        // search and Update
        const res = await filesServices.getAllByContactId(filters);
        this.files = res.data.rows;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.files.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.focusFirstElement();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onExport(parentFile) {
      try {
        let resReport = null;
        if (parentFile.sector.name === "cytology") {
          resReport = await cytologyReportsServices.getIdByFileId(parentFile.id);
        } else if (parentFile.sector.name === "histology") {
          resReport = await histologyReportsServices.getIdByFileId(parentFile.id);
        }
        const resFile = await commonServices.export(parentFile.sector.name + "Reports", resReport.data.id, "pdf");
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, parentFile.accessNumber + ".pdf");

          // update downloaded icon in list
          for (const file of this.files) {
            if (file.id === parentFile.id) {
              for (const fileContact of file.fileContacts) {
                if (fileContact.contactId === this.$userSettings.user.id) {
                  fileContact.downloaded = true;
                  break;
                }
              }
              break;
            }
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>
