import { render, staticRenderFns } from "./App.vue?vue&type=template&id=565aadad&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "@shared/views/General/App-global.scss?vue&type=style&index=0&lang=scss&"
import style1 from "@shared/views/General/App.scss?vue&type=style&index=1&id=565aadad&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "565aadad",
  null
  
)

export default component.exports