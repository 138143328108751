import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  setPassword(form) {
    return axiosServices.axios.put("/contacts/setPassword", { form: form });
  },
  putAccount(form, id) {
    return axiosServices.axios.put("/contacts/putAccount", { form: form, id: id });
  }
};
