<template>
  <div class="kt-contact-account-edit">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- user form -->
        <b-col cols="12">
          <!-- email -->
          <b-form-group
            :label="$t('contactAccounts.email')"
            :invalid-feedback="emailInvalidFeedback"
            :state="emailState"
          >
            <b-form-input
              ref="emailInput"
              v-model="email"
              type="email"
              trim
              maxlength="320"
              :state="emailState"
              @blur="emailValidation = true"
            ></b-form-input>
          </b-form-group>

          <!-- phone -->
          <b-form-group
            :label="$t('contactAccounts.phone')"
            :invalid-feedback="phoneInvalidFeedback"
            :state="phoneState"
          >
            <b-form-input
              v-model="phone"
              class="kt-input--prefixed"
              trim
              :state="phoneState"
              @blur="phoneValidation = true"
            ></b-form-input>
            <!-- prefix -->
            <div class="kt-input-prefix">
              <div class="kt-input-prefix__content">
                {{ $t('general.phoneCountryCodeDefault') }}
              </div>
            </div>
          </b-form-group>

          <!-- civility -->
          <b-form-group
            :label="$t('contactAccounts.civility')"
            :invalid-feedback="sexInvalidFeedback"
            :state="sexState"
          >
            <b-form-radio-group
              v-model="sexId"
              :options="sexOptions"
              value-field="id"
              text-field="localisedName"
              :state="sexState"
            ></b-form-radio-group>
          </b-form-group>

          <!-- firstName -->
          <InputTextFormatted
            v-model="firstName"
            :labelProp="$t('contactAccounts.firstName')"
            :stateProp="firstNameState"
            :invalidFeedbackProp="firstNameInvalidFeedback"
            @blur="firstNameValidation = true"
            @submit="onSubmit"
          />

          <!-- lastName -->
          <InputTextFormatted
            v-model="lastName"
            :labelProp="$t('contactAccounts.lastName')"
            :stateProp="lastNameState"
            :invalidFeedbackProp="lastNameInvalidFeedback"
            @blur="lastNameValidation = true"
            @submit="onSubmit"
          />

          <!-- password -->
          <b-form-group
            class="mb-2"
            :label="$t('contactAccounts.password')"
            :invalid-feedback="passwordInvalidFeedback"
            :state="passwordState"
          >
            <b-form-input
              ref="passwordField"
              v-model="password"
              class="kt-formField--password"
              autocomplete="off"
              maxlength="64"
              :type="isPasswordVisible ? 'text' : 'password'"
              :state="passwordState"
              @focus="arePasswordLabelsVisible = true"
            ></b-form-input>
            <!-- show password -->
            <div class="kt-show-password">
              <b-button
                class="kt-show-password__button"
                tabindex="-1"
                @click="onShowPassword('passwordField', 'isPasswordVisible')"
              >
                <b-icon
                  v-show="isPasswordVisible"
                  :class="'text-' + $systemSettings.theme"
                  icon="eye-slash-fill"
                ></b-icon>
                <b-icon
                  v-show="!isPasswordVisible"
                  icon="eye-fill"
                ></b-icon>
              </b-button>
            </div>
            <!-- password badges -->
            <div
              v-if="arePasswordLabelsVisible"
              class="kt-password-entry-badges"
            >
              <!-- minLength -->
              <span
                v-if="!password || !(password.length >= 12)"
                class="kt-badge kt-badge--blue"
              >{{
                $t("passwordBadges.minLenghtBadge")
              }}</span>
              <!-- lowercase -->
              <span
                v-if="!/^(?=.*[a-z]).{1,}$/.test(password)"
                class="kt-badge kt-badge--blue"
              >{{
                $t("passwordBadges.lowercaseBadge")
              }}</span>
              <!-- uppercase -->
              <span
                v-if="!/^(?=.*[A-Z]).{1,}$/.test(password)"
                class="kt-badge kt-badge--blue"
              >{{
                $t("passwordBadges.uppercaseBadge")
              }}</span>
              <!-- number -->
              <span
                v-if="!/^(?=.*\d).{1,}$/.test(password)"
                class="kt-badge kt-badge--blue"
              >{{ $t("passwordBadges.numberBadge") }}</span>
            </div>
          </b-form-group>

          <!-- passwordCheck -->
          <b-form-group
            :invalid-feedback="passwordCheckInvalidFeedback"
            :state="passwordCheckState"
          >
            <b-form-input
              ref="passwordCheckField"
              v-model="passwordCheck"
              class="kt-formField--password"
              autocomplete="off"
              maxlength="64"
              :type="isPasswordCheckVisible ? 'text' : 'password'"
              :placeholder="$t('contactAccounts.repeatPassword')"
              :state="passwordCheckState"
              @blur="passwordCheckValidation = true"
            ></b-form-input>
            <!-- show password -->
            <div class="kt-show-password">
              <b-button
                class="kt-show-password__button"
                tabindex="-1"
                @click="onShowPassword('passwordCheckField', 'isPasswordCheckVisible')"
              >
                <b-icon
                  v-show="isPasswordCheckVisible"
                  :class="'text-' + $systemSettings.theme"
                  icon="eye-slash-fill"
                ></b-icon>
                <b-icon
                  v-show="!isPasswordCheckVisible"
                  icon="eye-fill"
                ></b-icon>
              </b-button>
            </div>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-0 mb-3"></div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <!-- submit -->
          <b-button
            :variant="$systemSettings.theme"
            pill
            type="submit"
          >
            {{ $t("general.save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
import contactsServices from "@/services/API/contactsServices";
// helpers
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";

export default {
  components: { InputTextFormatted },
  mixins: [error, phoneNumber],
  props: {
    contactDataProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    contactIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general variables
      editMode: this.editModeProp,
      contactId: this.contactIdProp,
      contactData: null,
      // form data
      email: "",
      sexId: 2,
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      passwordCheck: "",
      // extra variables
      arePasswordLabelsVisible: false,
      isPasswordVisible: false,
      isPasswordCheckVisible: false,
      // is validation active
      emailValidation: false,
      sexValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      phoneValidation: false,
      passwordValidation: false,
      passwordCheckValidation: false
    };
  },
  computed: {
    // form validation
    emailState: function() {
      if (!this.emailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (this.email === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },
    sexState: function() {
      if (!this.sexValidation) return null;
      return this.sexId && this.sexId > 0 ? null : false;
    },
    sexInvalidFeedback: function() {
      return this.sexState === false ? this.$t("validationRules.required") : "";
    },
    firstNameState: function() {
      if (!this.firstNameValidation) return null;
      return this.firstName && this.firstName.length > 0 ? null : false;
    },
    firstNameInvalidFeedback: function() {
      return this.firstNameState === false ? this.$t("validationRules.required") : "";
    },
    lastNameState: function() {
      if (!this.lastNameValidation) return null;
      return this.lastName && this.lastName.length > 0 ? null : false;
    },
    lastNameInvalidFeedback: function() {
      return this.lastNameState === false ? this.$t("validationRules.required") : "";
    },
    phoneState: function() {
      // validation active
      if (!this.phoneValidation) return null;
      // not required
      if (this.phone === "") return null;

      return this.phone && this.phone.length > 0 && /^0?[67]\d{8}$/.test(this.phone) ? null : false;
    },
    phoneInvalidFeedback: function() {
      // no error
      if (this.phoneState === null) return "";
      // if empty
      if (!this.phone) return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidMobilePhone");
    },
    passwordState: function() {
      if (!this.passwordValidation) return null;
      if (this.password === "" && this.passwordCheck === "") return null;
      return this.password && this.password.length >= 12 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password) && this.password === this.passwordCheck ? null : false;
    },
    passwordInvalidFeedback: function() {
      // no error
      if (this.passwordState === null) return "";
      // error but no notification
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password)) return "";
      // else : passwords not equals
      return this.$t("contactAccounts.validations.passwordNotEquals");
    },
    passwordCheckState: function() {
      if (!this.passwordCheckValidation) return null;
      if (this.password === "" && this.passwordCheck === "") return null;
      return this.passwordCheck && this.passwordCheck.length > 0 && this.password === this.passwordCheck ? null : false;
    },
    passwordCheckInvalidFeedback: function() {
      // no error
      if (this.passwordCheckState === null) return "";
      // if empty
      if (!this.passwordCheck) return this.$t("validationRules.required");
      // else : passwords not equals
      return this.$t("contactAccounts.validations.passwordNotEquals");
    },

    sexOptions: function() {
      return this.$systemSettings.sexes;
    }
  },
  async mounted() {
    await this.importData();

    // auto-focus
    if (!this.moduleModeProp) {
      this.focusFirstElement();
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.emailInput.focus();
    },

    // load data
    async importData() {
      if (this.editMode) {
        // import from prop
        if (this.contactDataProp !== null) {
          this.email = this.contactDataProp.email;
          this.sexId = this.contactDataProp.sexId;
          this.firstName = this.contactDataProp.firstName;
          this.lastName = this.contactDataProp.lastName;
          this.phone = this.contactDataProp.phone ? this.importFormatPhone(this.contactDataProp.phone) : "";
          this.password = "";
          this.passwordCheck = "";
        }
      }
    },
    // handle show/hide on the password fields
    onShowPassword(refTarget, showVariableName) {
      this[showVariableName] = !this[showVariableName];
      this.$nextTick(() => this.$refs[refTarget].focus());
    },

    onValidate() {
      this.emailValidation = true;
      this.sexValidation = true;
      this.firstNameValidation = true;
      this.lastNameValidation = true;
      this.phoneValidation = true;
      this.passwordValidation = true;
      this.passwordCheckValidation = true;

      return !(
        this.emailState === false ||
        this.sexState === false ||
        this.firstNameState === false ||
        this.lastNameState === false ||
        this.phoneValidation === false ||
        this.passwordState === false ||
        this.passwordCheckState === false
      );
    },
    onClearValidate() {
      this.emailValidation = false;
      this.sexValidation = false;
      this.firstNameValidation = false;
      this.lastNameValidation = false;
      this.phoneValidation = false;
      this.passwordValidation = false;
      this.passwordCheckValidation = false;
    },
    async onSubmit() {
      try {
        // Validate Form
        if (this.onValidate() === false) return false;

        const form = {
          email: this.email,
          sexId: this.sexId,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.exportFormatPhone(this.phone),
          password: this.password
        };

        if (this.editMode) {
          // EDIT MODE
          if (this.password === "") delete form.password;

          const res = await contactsServices.putAccount(form, this.contactId);
          if (res.data === true) {
            // success
            this.onClearValidate();
            this.$emit("alert", "success", {
              title: this.$t("contactAccountEdit.notifications.editedTitle"),
              message: this.$t("contactAccountEdit.notifications.editedText")
            });
            form.id = this.contactId;
            if (form.password !== undefined) delete form.password;
            this.$emit("contactEdited", form);
          } else if (res.data.error === "emailInUse") {
            this.$emit("alert", "danger", {
              title: this.$t("contactAccountEdit.notifications.emailInUseTitle"),
              message: this.$t("contactAccountEdit.notifications.emailInUseText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("contactAccountEdit.notifications.editionErrorTitle"),
              message: this.$t("contactAccountEdit.notifications.editionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>
