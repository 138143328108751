<template>
  <div
    class="kt-app"
    :class="loading ? 'kt-app--loading' : ''"
  >
    <!-- app loading animation -->
    <div class="kt-app__loading">
      <span class="kt-app__loading__text">{{ $t("general.appName") }}</span>
    </div>
    <!-- mobile warning -->
    <div class="kt-mobile-warning">
      <span class="kt-mobile-warning__text">{{ $t("general.mobileWarning") }}</span>
    </div>

    <!-- app container -->
    <div class="kt-app__container">
      <!-- app sidebar -->
      <div class="kt-app__sidebar-left"></div>

      <!-- app wrapper -->
      <div class="kt-app__wrapper">
        <!-- main nav -->
        <header class="kt-app__header">
          <!-- main header top -->
          <div class="kt-app__header-top">
            <b-container fluid="xxl">
              <b-row>
                <!-- app logo -->
                <b-col>
                  <div class="kt-logo">
                    <a
                      class="kt-logo__link"
                      href="/"
                      @click.prevent="goToDefaultPageByRight"
                    >
                      {{ $t("general.appName") }}
                      <span
                        class="kt-logo__userType"
                      >{{ $t("general.resultsAppName") }}</span>
                    </a>
                    <a
                      v-show="isLoggedIn && logoFile && logoUrl"
                      class="kt-logo__secondary"
                      @click.prevent="goToDefaultPageByRight"
                    >
                      <b-img
                        class="kt-logo__secondary-img"
                        :src="logoUrl"
                        :alt="$t('laboratories.logo')"
                      ></b-img>
                    </a>
                  </div>
                </b-col>

                <!-- actions right -->
                <b-col class="col-md-auto">
                  <div v-if="isLoggedIn">
                    <!-- user button -->
                    <b-button
                      ref="login"
                      class="kt-app__header-top__user-btn"
                      :variant="'outline-' + $systemSettings.theme"
                      @click="$refs.contactEditModal.show"
                    >
                      <b-icon icon="person"></b-icon>
                      {{ $userSettings.user ? $userSettings.user.firstName + ' ' + $userSettings.user.lastName : '' }}
                    </b-button>
                    <!-- logout button -->
                    <b-button
                      ref="logout"
                      class="kt-app__header-top__user-btn"
                      :variant="'outline-' + $systemSettings.theme"
                      @click="onLogout"
                    >
                      <b-icon icon="power"></b-icon>
                      {{ $t("session.login.logout") }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </header>

        <!-- notifications -->
        <div class="kt-notifications">
          <div
            class="kt-notifications__wrapper fixed-top"
            style="z-index: 2000;"
          >
            <b-alert
              ref="alert"
              v-model="alert.show"
              class="kt-alert"
              :variant="alert.variant"
              dismissible
              fade
            >
              <strong>{{ alert.strings.title }}</strong>
              <div
                v-show="alert.strings.message"
              >
                {{ alert.strings.message }}
              </div>
            </b-alert>
          </div>
        </div>

        <!-- main content -->
        <div class="kt-app__main-content">
          <b-container :fluid="layout === 'fullWidth' ? true : 'xxl'">
            <b-row>
              <b-col
                :class="{'p-0': layout === 'fullWidth'}"
                style="position: static;"
              >
                <!-- main router -->
                <router-view
                  v-if="(!loading && isLoggedIn) || noAuth"
                  :key="$route.fullPath"
                  @alert="onAlert"
                  @contactEdited="onContactEdited"
                />
              </b-col>
            </b-row>
          </b-container>

          <!-- login inpage -->
          <section
            v-if="!isLoggedIn && !noAuth"
            class="kt-login-inpage kt-login-inpage--results"
          >
            <div class="kt-login-inpage__container">
              <div class="kt-login-inpage__wrapper">
                <!-- header -->
                <header class="kt-login-inpage__header mt-5 text-center">
                  <h1 class="kt-login-inpage__header-title h4">
                    <span
                      class="kt-login-inpage__title-decoration"
                    >{{ $t("session.home.appName") }}</span>
                    <span
                      class="kt-login-inpage__title-text"
                    >{{ $t("session.home.punchline") }}</span>
                  </h1>
                  <div
                    v-show="logoFile && logoUrl"
                    class="kt-login-inpage__client-logo"
                  >
                    <b-img
                      class="kt-login-inpage__logo-img"
                      :src="logoUrl"
                      :alt="$t('laboratories.logo')"
                    ></b-img>
                  </div>
                </header>

                <!-- login form -->
                <div
                  v-if="showLoginInpage"
                  class="kt-login-inpage__card"
                >
                  <Login
                    @loggedIn="onLoggedIn"
                    @forgotPassword="openLoginSections('showForgotPasswordInpage')"
                    @alert="onAlert"
                  />
                </div>

                <!-- forgot password form -->
                <div
                  v-if="showForgotPasswordInpage"
                  class="kt-login-inpage__card"
                >
                  <ForgotPassword
                    @passwordForgotten="
                      forgotPasswordReturn = $event;
                      openLoginSections('showForgotPasswordSuccessInpage');
                    "
                    @goToLogin="openLoginSections('showLoginInpage')"
                    @alert="onAlert"
                  />
                </div>

                <!-- forgot password success -->
                <div
                  v-if="showForgotPasswordSuccessInpage"
                  class="kt-login-inpage__card"
                >
                  <ForgotPasswordSuccess
                    :forgotPasswordReturnProp="forgotPasswordReturn"
                    @goToLogin="openLoginSections('showLoginInpage')"
                    @alert="onAlert"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <!-- user edit modal -->
      <b-modal
        ref="contactEditModal"
        hide-footer
        @shown="() => {
          $refs.contactEditComponent.focusFirstElement()
        }"
      >
        <template #modal-title>
          <b-icon icon="person"></b-icon>
          {{ $userSettings.user ? $userSettings.user.firstName + ' ' + $userSettings.user.lastName : '' }}
        </template>

        <UserEdit
          ref="contactEditComponent"
          :contactIdProp="$userSettings.user ? $userSettings.user.id : null"
          :contactDataProp="$userSettings.user"
          :editModeProp="true"
          moduleModeProp
          @contactEdited="onContactEdited"
          @alert="onAlert"
        />
      </b-modal>
      <!-- file appReloadRequired Modal  -->
      <b-modal
        :id="'appReloadRequiredModal-' + _uid"
        hide-footer
        hide-header
        @shown="() => {
          $refs.appReloadRequiredButton.focus()
        }"
      >
        <div class="text-center py-1">
          <h4
            style="font-size: 18px;
            letter-spacing: -0.03px;
            margin-bottom: 12px;"
          >
            <b-icon
              icon="star"
              style="margin-right: 6px;"
              class="text-warning"
            ></b-icon>
            {{ $t('session.appReloadRequired.title') }}
          </h4>
          <p
            class="text-14"
            style="margin-bottom: 8px;"
          >
            {{ $t('session.appReloadRequired.text') }}
          </p>
          <b-button
            ref="appReloadRequiredButton"
            :variant="$systemSettings.theme"
            size="sm"
            @click="appRefresh"
          >
            <b-icon icon="arrow-clockwise"></b-icon>
            {{ $t('session.appReloadRequired.button') }}
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import p2r from "path-to-regexp";

// components
import Login from "@shared/views/General/Onboarding/Login/Login.vue";
import ForgotPassword from "@shared/views/General/Onboarding/Login/ForgotPassword.vue";
import ForgotPasswordSuccess from "@shared/views/General/Onboarding/Login/ForgotPasswordSuccess.vue";
import UserEdit from "@/views/Administration/Contacts/ContactEdit.vue";
// services
import commonServices from "@shared/services/API/commonServices";
import laboratoriesServices from "@/services/API/laboratoriesServices";
import sessionsServices from "@/services/API/sessionsServices";
import axiosServices from "@shared/services/API/axiosServices";
// helpers
import { navigate, router } from "@/services/UI/vueRouterServices";
import error from "@shared/services/UI/error";

export default {
  name: "App",
  components: {
    Login,
    UserEdit,
    ForgotPassword,
    ForgotPasswordSuccess
  },
  mixins: [error],
  data() {
    return {
      loading: true,
      isLoggedIn: false,
      alert: {
        show: false,
        strings: {
          title: "",
          message: ""
        },
        variant: "danger"
      },
      // client logo
      logoFile: null,
      logoUrl: null,
      // highlight
      isHighlightLoaded: false,
      // login / register / password
      registerReturn: { firstName: "", lastName: "", email: "" },
      forgotPasswordReturn: "",
      showLoginInpage: true,
      showForgotPasswordInpage: false,
      showForgotPasswordSuccessInpage: false
    };
  },
  computed: {
    layout: function() {
      return (this.$route.meta.layout || "default");
    },
    noAuth: function() {
      return Boolean(this.$route.meta.noAuth);
    }
  },
  watch: {
    $systemSettings: {
      handler: async function(val) {
        // update the html lang attribute
        const lang = val && val.locale ? val.locale : "en";
        const currentLang = document.getElementsByTagName("html")[0].lang;
        if (lang !== currentLang) {
          document.getElementsByTagName("html")[0].lang = lang;
        }
        // dynamic import for Highlight styles
        if (val.isTestMode && !this.isHighlightLoaded) {
          this.isHighlightLoaded = true;
          await import("@shared/views/General/Highlight.scss");
        }
      }
    }
  },
  async mounted() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler, false);
    window.addEventListener("load", this.loadHandler, false);

    axiosServices.axios.interceptors.request.use(
      (config) => {
        this.addVersion(config);
        return config;
      });
    axiosServices.axios.interceptors.response.use(
      async (response) => {
        // every HTTP response 2xx codes
        // console.log("axios.interceptors.response", response.data);
        if (response && response.data && response.data.error) {
          if (response.data.error === "userNotLoggedIn") {
            console.log("userNotLoggedIn");
            // onLogout
            await this.onLogout();
            this.onAlert("warning", {
              title: this.$t("session.logout.expiredSessionTitle"),
              message: this.$t("session.logout.expiredSessionText")
            });
            this.$systemSettings.onLogout = false;
          }
          if (response.data.error === "appReloadRequired") {
            // onAppReload
            this.$bvModal.show("appReloadRequiredModal-" + this._uid);
          }
        }
        return response;
      },
      (resError) => {
        // every HTTP response codes - except 2xx
        return Promise.reject(resError);
      });
    await this.importSystemSettings();
    await this.importClientLogo();
  },
  methods: {
    async importClientLogo() {
      try {
        // import logo
        const resLogo = await laboratoriesServices.getLogo();
        if (resLogo.data) {
          this.logoFile = new File([resLogo.data], "laboratory");
          this.logoUrl = window.URL.createObjectURL(this.logoFile);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async importSystemSettings() {
      try {
        const res = await commonServices.getAll("systemSettings");
        this.$systemSettings = {
          ...this.$systemSettings,
          ...res.data
        };
        this.$systemSettings.basicRouteRegexps = [];
        for (const basicRoute of this.$systemSettings.basicRoutes) {
          this.$systemSettings.basicRouteRegexps.push(p2r(basicRoute));
        }
        // theme
        this.updateBodyVariantClass();
        // localisation
        this.$i18n.locale = this.$systemSettings.locale;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async importContextData(importLoggedInData = true) {
      if (importLoggedInData) {
        const resSectors = this.importSectors();
        await resSectors;
      }
      const resSexes = this.importSexes();
      await resSexes;
    },
    async importSectors() {
      try {
        const resSectors = await commonServices.getAll("sectors");
        this.$systemSettings.sectors = resSectors.data.map((item) => {
          item.localisedName = this.$t("sectors." + item.name) + (!item.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : "");
          return item;
        });
        return true;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async importSexes() {
      try {
        if (this.$systemSettings.sexes && this.$systemSettings.sexes.length === 0) {
          const resSexes = await commonServices.getAll("sexes");
          this.$systemSettings.sexes = resSexes.data.map((sex) => {
            sex.localisedName = this.$t("sexes." + sex.name);
            return sex;
          });
        }
        return true;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    beforeUnloadHandler(event) {
      if (this.isLoggedIn) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave? Your session will be ended.";
      }
    },
    async loadHandler(_event) {
      try {
        const res = await sessionsServices.isLoggedIn();
        if (res.data.loggedIn) {
          const user = {
            id: res.data.id,
            email: res.data.email,
            sexId: res.data.sexId,
            sex: res.data.sex,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            phone: res.data.phone || ""
          };
          this.$userSettings.user = user;
          await this.importContextData();
          this.addCheckAuthOnRouterAndRequests(this.onLogout);
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
          await this.importContextData(false);
          this.clearUser();
        }
        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    getCookies() {
      return document.cookie.split(";").reduce((prev, current) => {
        const [name, value] = current.split(/\s?(.*?)=(.*)/).splice(1, 2);
        prev[name] = value;
        return prev;
      }, {});
    },
    refreshExpiry() {
      const expiry = window.location.hostname + "_expiryDate";
      const expiryValue = moment().add(this.$systemSettings.automaticLogout, "m");
      document.cookie = expiry + "=" + expiryValue.format() + ";path=/" + ";expires=" + expiryValue.toDate().toUTCString() + ";sameSite=Strict" + ";secure=true";
    },
    checkCookiesForVue(to, _from, next) {
      const cookies = this.getCookies();
      const results = window.location.hostname;
      const expiry = window.location.hostname + "_expiryDate";
      if (to.name === "home") {
        next();
      } else if (cookies[results]) {
        // For firefox which does NOT delete expired cookies
        const expiryDate = moment(cookies[expiry]);
        const isBefore = moment().isBefore(expiryDate);
        if (isBefore) {
          this.refreshExpiry();
          next();
        } else {
          next(new Error());
        }
      } else {
        next(new Error());
      }
    },
    addVersion(config) {
      config.headers.kitaroversion = this.$systemSettings.kitaroversion;
      return config;
    },
    async checkCookiesForAxios(config) {
      const cookies = this.getCookies();
      const results = window.location.hostname;
      const expiry = window.location.hostname + "_expiryDate";
      let found = false;
      for (const basicRouteRegex of this.$systemSettings.basicRouteRegexps) {
        if (basicRouteRegex.test(config.url)) {
          found = true;
          break;
        }
      }
      if (found) {
        // Do nothing
      } else if (cookies[results]) {
        // For firefox which does NOT delete expired cookies
        const expiryDate = moment(cookies[expiry]);
        const isBefore = moment().isBefore(expiryDate);
        if (isBefore) {
          this.refreshExpiry();
        } else {
          await this.onLogout();
        }
      } else {
        await this.onLogout();
      }
      return config;
    },
    addCheckAuthOnRouterAndRequests() {
      router.beforeEach(this.checkCookiesForVue);
      router.onError(this.onLogout);
      axiosServices.axios.interceptors.request.use(
        async (config) => {
          this.addVersion(config);
          await this.checkCookiesForAxios(config);
          return config;
        },
        async () => {
          await this.onLogout();
        });
    },

    // log in
    async onLoggedIn(user) {
      if (user.authenticated) {
        this.refreshExpiry();
        this.addCheckAuthOnRouterAndRequests(this.onLogout);
        this.updateContact(user);
        await this.importContextData();
        this.isLoggedIn = true;
        // go to the default page for this user
        this.goToDefaultPage();
      } else {
        await this.importContextData(false);
      }
    },
    // logout
    async onLogout() {
      try {
        const res = await sessionsServices.logout();
        this.clearUser();
        if (!res.data) {
          this.onAlert("warning", {
            title: this.$t("session.logout.logoutErrorTitle"),
            message: this.$t("session.logout.logoutErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
      this.$refs.contactEditModal.hide();
      this.isLoggedIn = false;
      navigate("home");
    },
    // appRefresh
    appRefresh() {
      window.removeEventListener("beforeunload", this.beforeUnloadHandler);
      window.location.reload(true);
    },
    // alert
    onAlert(variant, strings) {
      this.alert.show = false;
      this.alert.variant = variant;
      this.alert.strings = strings;
      if (variant === "danger") {
        this.alert.show = true;
      } else if (variant === "info" || variant === "warning") {
        this.alert.show = 6;
      } else {
        this.alert.show = 3;
      }
    },
    // edit user
    onContactEdited(data) {
      if (this.$userSettings.user && data.id === this.$userSettings.user.id) {
        this.updateContact(data);
      }
      this.$refs.contactEditModal.hide();
    },

    // helpers
    updateBodyVariantClass() {
      const appBody = document.body;
      appBody.className = "";
      appBody.classList.add("kt-app-body--" + this.$systemSettings.theme);
    },
    openLoginSections(target = null) {
      this.showLoginInpage = target === "showLoginInpage";
      this.showForgotPasswordInpage = target === "showForgotPasswordInpage";
      this.showForgotPasswordSuccessInpage = target === "showForgotPasswordSuccessInpage";
    },
    updateContact(user) {
      if (!this.$userSettings.user) this.$userSettings.user = {};
      if (user.id !== undefined) this.$userSettings.user.id = user.id;
      this.$userSettings.user.email = user.email;
      this.$userSettings.user.sexId = user.sexId;
      this.$userSettings.user.firstName = user.firstName;
      this.$userSettings.user.lastName = user.lastName;
      this.$userSettings.user.phone = user.phone ? user.phone : "";
    },
    clearUser() {
      this.$userSettings.user = null;
      this.$userSettings.savedBehaviors = {
        ...this.$userSettings.savedBehaviors,
        diagnosis: {
          activeSector: ""
        },
        accounting: {
          paymentType: "",
          activeAccountingSection: ""
        }
      };
    },
    goToDefaultPage() {
      navigate("results");
    },
    goToDefaultPageByRight() {
      navigate("results");
    }
  }
};
</script>

<style src="@shared/views/General/App-global.scss" lang="scss"></style>
<style scoped src="@shared/views/General/App.scss" lang="scss"></style>
