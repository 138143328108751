import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  isLoggedIn() {
    return axiosServices.axios.get("/contactSessions/isLoggedIn");
  },
  login(credentials) {
    return axiosServices.axios.post("/contactSessions/login", credentials);
  },
  sendSmsToken(credentials) {
    return axiosServices.axios.post("/contactSessions/sendSmsToken", credentials);
  },
  authenticate(credentials) {
    return axiosServices.axios.post("/contactSessions/authenticate", credentials);
  },
  logout() {
    return axiosServices.axios.post("/contactSessions/logout");
  },
  forgotPassword(email) {
    return axiosServices.axios.post("/contacts/forgotPassword", { email: email });
  },
  generateSecret() {
    return axiosServices.axios.post("/contactSessions/generateSecret");
  }
};
