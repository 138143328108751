const userRightsTable = require("./userRightsTable");

export default {
  methods: {
    isEnabled(keys) {
      if (!this.$userSettings.user || !this.$userSettings.user.userRights) {
        return false;
      }

      if (this.$userSettings.user.userRights.includes("Administrator")) {
        return true;
      }

      let rights = userRightsTable.rights;
      for (const key of keys) {
        if (rights[key]) {
          rights = rights[key];
        } else {
          return false;
        }
      }
      for (const userRight of this.$userSettings.user.userRights) {
        if (rights.includes(userRight)) {
          return true;
        }
      }
      return false;
    },
    isRole(role) {
      if (!this.$userSettings.user || !this.$userSettings.user.userRights) {
        return false;
      }

      if (this.$userSettings.user.userRights.includes("Administrator")) {
        return role === "system";
      } else if (this.$userSettings.user.userRights.includes("UserAdministrator")) {
        return role === "userAdmin";
      } else if (this.$userSettings.user.userRights.includes("Contact")) {
        return role === "contact";
      } else {
        return false;
      }
    }
  }
};
