<template>
  <div class="kt-results mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("results.pageTitle") }}
      </h2>
    </div>

    <!-- Navbar -->
    <b-nav
      class="kt-sub-nav mb-3"
      :class="'kt-sub-nav--' + $systemSettings.theme"
      style="margin-top: -2px;"
    >
      <!-- available -->
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'results'"
        @click="navigateInSubmenu('results', null)"
      >
        {{ $t("results.navigation.available") }}
      </b-nav-item>
      <!-- pending -->
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'results/pending'"
        @click="navigateInSubmenu('results/pending', null)"
      >
        {{ $t("results.navigation.pending") }}
      </b-nav-item>
    </b-nav>

    <!-- router -->
    <keep-alive>
      <router-view
        ref="childComponent"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </keep-alive>
  </div>
</template>

<script>
// others
import { navigate, createQueryFromParams } from "@/services/UI/vueRouterServices";

export default {
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;
  },
  methods: {
    navigateInSubmenu(name, props) {
      let formattedQuery = null;
      if (this.$refs.childComponent) {
        formattedQuery = createQueryFromParams(this.$refs.childComponent.paramsValues);
      }
      this.navigate(name, props, formattedQuery.queryObject);
    }
  }
};
</script>
